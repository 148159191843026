import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import { Button } from "../../../../../atomes";

const Tabs = ({
  // use Map instead of an array for tabs
  tabs = [
    {
      name: "",
      value: "",
      component: () => <p>this component is empty</p>,
      args: {},
    },
  ],
  style = {},
  location = { hash: null, pathname: "" },
  parent = "",
}) => {
  const getInitialValue = () => {
    const { hash } = location;

    if (parent) {
      return hash ? hash.split("#").slice(-1)[0] : tabs[0]?.value;
    } else {
      return hash ? hash.split("#").slice(1)[0] : tabs[0]?.value;
    }
  };
  const [selectedTab, setSelectedTab] = useState(getInitialValue());
  useEffect(() => {
    setSelectedTab(getInitialValue());
  }, [location.hash]);

  const updateSelectedTab = tab => e => {
    e.preventDefault();
    if (parent) {
      navigate(`${location.pathname}#${parent}#${tab}`);
    } else {
      navigate(`${location.pathname}#${tab}`);
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {tabs.length > 0
          ? tabs.map(tab => {
              return (
                <Button
                  key={`${tab.value}title`}
                  className={`${selectedTab === tab.value ? "selected" : ""}`}
                  type="link"
                  handelClick={updateSelectedTab(tab.value)}
                  style={style}
                >
                  {tab.name}
                </Button>
              );
            })
          : null}
      </div>
      <div>
        {tabs.length > 0
          ? tabs.map(tab => {
              const Component = tab.component;
              return (
                selectedTab === tab.value && (
                  <Component key={tab.value} {...tab.args} />
                )
              );
            })
          : null}
      </div>
    </>
  );
};

export default Tabs;
