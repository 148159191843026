import React, { useState } from "react";
import { Link } from "gatsby";

const itemsInit = count => {
  const states = [];
  for (let c = 0; c < count; c++) {
    states.push(false);
  }
  return states;
};

const Corridor = ({ items }) => {
  let [isOpen, setIsOpen] = useState(itemsInit(items.length));

  const toggleOpner = itemNumber => event => {
    event.preventDefault();
    setIsOpen(isOpen.map((v, index) => (itemNumber === index ? !v : false)));
  };

  return (
    <div id="menu">
      <ul>
        {items.map(([title, contents], index) => {
          return (
            <li key={index}>
              <span
                className={`opener ${isOpen[index] ? "active" : ""}`}
                onClick={toggleOpner(index)}
              >
                {title}
              </span>
              <ul>{contents}</ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Corridor;
