import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import CoursePreview from "../components/CoursePreview";
import Tabs from "components/dashboard/TeacherDashboard/components/molecules/Tabs/Tabs";
import { unites } from "utility/const";
import Corridor from "components/Corridor";

const OrderByUnite = ({ data = [], category, title }) => {
  console.log(
    "in order by unit",
    data.map(v => v.course.frontmatter.unite)
  );
  const unitesTitle = unites[category].reduce(
    (acc, v) => ({ ...acc, [v]: [] }),
    {}
  );

  const results = data.reduce((acc, { course }) => {
    acc[course.frontmatter.unite]?.push(course);
    return acc;
  }, unitesTitle);
  const items = Object.entries(results).map(([header, contents]) => {
    return [header, <Content title={title} data={contents} />];
  });
  return <Corridor items={items} />;
};
const Content = ({ data = [], title }) => {
  return (
    <div className="posts">
      {data.length > 0 && (
        <>
          <h4 style={{ width: "100%" }}>العدد: {data.length}</h4>
        </>
      )}
      {data.length > 0 ? (
        (data.length + Math.floor(data.length / 5) + 1) % 2 === 0 ? (
          data.map((course, index) => {
            return (
              <>
                {/* {index % 5 === 0 ? <ListingAds /> : null} */}
                <CoursePreview
                  key={index}
                  course={{
                    title: course?.frontmatter?.title,
                    slug: course?.fields?.slug,
                    fluid: {
                      ...course?.frontmatter?.thumbnail.childImageSharp.fluid,
                      originalName: course?.frontmatter?.thumbnail?.childImageSharp?.fluid?.originalName?.replace(
                        /Banner|.png/g,
                        ""
                      ),
                    },
                    description:
                      course?.frontmatter?.description || course?.excerpt,
                  }}
                  loading={index > 3 ? "eager" : "lazy"}
                />
              </>
            );
          })
        ) : (
          [
            ...data.map((course, index) => {
              return (
                <>
                  {/* {index % 5 === 0 ? <ListingAds /> : null} */}
                  <CoursePreview
                    key={index}
                    course={{
                      title: course?.frontmatter?.title,
                      slug: course?.fields?.slug,
                      fluid: {
                        ...course?.frontmatter?.thumbnail.childImageSharp.fluid,
                        originalName: course?.frontmatter?.thumbnail?.childImageSharp?.fluid?.originalName?.replace(
                          /Banner|.png/g,
                          ""
                        ),
                      },
                      description:
                        course?.frontmatter?.description || course?.excerpt,
                    }}
                    loading={index > 3 ? "eager" : "lazy"}
                  />
                </>
              );
            }),
            <div key={data.length}></div>,
          ]
        )
      ) : (
        <p>
          سوف يتم إضافة
          {` ال${title} `}
          قريبا
        </p>
      )}
    </div>
  );
};

const Unites = ({
  data: {
    courses: { nodes: coursesData },
    exercises: { nodes: exercisesData },
    series: { nodes: seriesData },
  },
  pageContext: { level, division },
  location,
}) => {
  const tabs = [
    {
      name: "دروس",
      value: "courses",
      component: OrderByUnite,
      args: {
        data: coursesData,
        title: "دروس",
        category: `${level} ${division}`,
      },
    },
    {
      name: "تمارين",
      value: "exercises",
      component: OrderByUnite,
      args: {
        data: exercisesData,
        title: "تمارين",
        category: `${level} ${division}`,
      },
    },
    {
      name: "سلاسل",
      value: "series",
      component: OrderByUnite,
      args: {
        data: seriesData,
        title: "سلاسل",
        category: `${level} ${division}`,
      },
    },
  ];
  return (
    <Layout title={`${level} ${division}`} location={location}>
      <Tabs tabs={tabs} style={{ fontSize: "1.5em" }} location={location} />
    </Layout>
  );
};

export default Unites;

export const query = graphql`
  query($level: String!, $division: String!) {
    courses: allFile(
      filter: {
        sourceInstanceName: { eq: "courses" }
        childMdx: {
          frontmatter: {
            level: { eq: $level }
            division: { eq: $division }
            lessonType: { eq: "درس" }
          }
        }
      }
      sort: { fields: childMdx___frontmatter___rank }
    ) {
      nodes {
        course: childMdx {
          fields {
            slug
          }
          frontmatter {
            title
            description
            unite
            thumbnail {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
          excerpt(truncate: true)
        }
      }
    }
    exercises: allFile(
      filter: {
        sourceInstanceName: { eq: "exercises" }
        childMdx: {
          frontmatter: {
            level: { eq: $level }
            division: { eq: $division }
            lessonType: { eq: "تمرين" }
          }
        }
      }
      sort: { fields: childMdx___frontmatter___rank }
    ) {
      nodes {
        course: childMdx {
          fields {
            slug
          }
          frontmatter {
            title
            unite
            description
            thumbnail {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
          excerpt(truncate: true)
        }
      }
    }
    series: allFile(
      filter: {
        sourceInstanceName: { eq: "series" }
        childMdx: {
          frontmatter: {
            level: { eq: $level }
            division: { eq: $division }
            lessonType: { eq: "سلسلة" }
          }
        }
      }
      sort: { fields: childMdx___frontmatter___rank }
    ) {
      nodes {
        course: childMdx {
          fields {
            slug
          }
          frontmatter {
            title
            unite
            description
            thumbnail {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
          excerpt(truncate: true)
        }
      }
    }
  }
`;
